import React from 'react';
import {useTranslation} from "react-i18next";
import {Button, Col, Row} from "react-bootstrap";
import {Globe} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

function Footer(props) {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        window.location.reload(true);
    }

    const languages = [
        {
            code: "en",
            name: "English"
        },
        {
            code: "ar",
            name: "العربية"
        },
    ];

    return (
        <footer className="footer text-dark">
            <Row>
               <Col className={i18n.language === "en" ? "text-start" : "text-end"}>
                    <span>
                        {t("rights text")}
                    </span>
               </Col>
                <Col className={"text-center"}>
                    <span>
                    {languages.map((lang, i) => {
                        return (
                            <Button
                                key={lang.code}
                                className={"mx-1 my-sm-1"}
                                onClick={() => changeLanguage(lang.code)}
                                disabled={lang.code === i18n.language}
                                size={"sm"}
                                variant={"light"}
                            >
                                <Globe size={"14px"} />
                                {" "}
                                {lang.name}
                            </Button>
                        );
                    })}
                </span>
                </Col>
                <Col className={i18n.language === "en" ? "text-end" : "text-start"} dir={"ltr"}>
                    <span>
                        <Link to={"/privacy-policy"} className={"mx-1"}>{t("Privacy Policy")}</Link>
                    </span>
                </Col>
            </Row>
        </footer>
    );
}

export default Footer;