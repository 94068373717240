import {Outlet, Route, Routes, useLocation} from "react-router-dom";
import {AnimatePresence} from 'framer-motion';
import {motion} from 'framer-motion';
import NotFound from "../components/NotFound";
import routes from "./routes";

function AnimatedRoute() {
    const location = useLocation();

    const getComponentsFromRoutes = (routeList, lv = 0) => {
        return routeList.map((route, idx) => {
            return <Route
                    key={lv + idx}
                    index={route.index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={ route.component
                        ?   <motion.div initial={{opacity: 0}} animate={{opacity: 1}} className={route.slim ? "p-0" : ""}>
                                <route.component />
                            </motion.div>

                        :   <Outlet />
                    }
                    children={
                        route.children ? getComponentsFromRoutes(route.children, lv + 100) : null
                    }
                />
        })
        .concat(
            <Route key={lv + 999} path="*" element={
                <motion.div initial={{opacity: 0}} animate={{opacity: 1}} >
                    <NotFound />
                </motion.div>}
            />
        );
    }

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                { getComponentsFromRoutes(routes) }
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoute;