import { BrowserRouter as Router, useLocation } from "react-router-dom";
import StyleSelector from "./style-selector/StyleSelector";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {useLayoutEffect} from "react";
import Navigation from "./components/Navigation";
import Header from "./components/Header";
import {Container, Row} from "react-bootstrap";
import Footer from "./components/Footer";
import AnimatedRoute from "./routing/AnimatedRoute";

function App() {
    const { t, i18n } = useTranslation();

    // Auto scroll to beginning on page change
    const ScrollWrapper = ({children}) => {
        const location = useLocation();

        useLayoutEffect(() => {
            document
                .documentElement
                .scrollTo(0, document.getElementById('main')?.offsetTop);
        }, [location.pathname]);

        return children;
    }

    return (
        <Router>
            <StyleSelector language={i18n.language}>
                <Helmet>
                    <html lang={i18n.language} dir={i18n.language === 'ar' ? "rtl" : "ltr"}/>
                    <title>Shams M. Monem | {t('Home')}</title>
                </Helmet>
                <ScrollWrapper>
                    <div id={"main"}>
                        <Container fluid={true} className={"p-0 mb-5"}>
                            <Row className={"w-100 m-0 p-0"}>
                                <Navigation/>
                                <br/>
                                <Header />
                            </Row>
                            <Row className={"w-100 m-0 p-0"}>
                                <AnimatedRoute />
                            </Row>
                        </Container>
                        <Footer/>
                    </div>
                </ScrollWrapper>
            </StyleSelector>
        </Router>
  );
}

export default App;
