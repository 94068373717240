import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {
    alabgadyBrand,
    blogBrand,
    bootstrapIcon, contentCreation,
    dbManagement, dockerIcon, electronics, flutter,
    fullStackDev, mariadb, mobileDev,
    mokawenatBrand,
    msdBrand, react,
    serverAdmin, spring, videoEditing, wordpress, yii
} from "../../assets";
import {useTranslation} from "react-i18next";

function Home(props) {
    const {t, i18n} = useTranslation();
    const en = i18n.language === "en";

    const stuffIDo = [
        {
            name: "Full-Stack Web Development",
            image: fullStackDev,
        },
        {
            name: "Server Administration",
            image: serverAdmin,
        },
        {
            name: "Database Management",
            image: dbManagement,
        },
        {
            name: "Mobile App Development",
            image: mobileDev,
        },
        {
            name: "Electronics, Embedded Systems, Arduino & Raspberry PI",
            image: electronics,
        },
        {
            name: "Video Editing",
            image: videoEditing,
        },
        {
            name: "Content Creation",
            image: contentCreation,
        },
    ];

    const technologiesIUse = [
        {
            name: "Spring Boot",
            image: spring,
        },
        {
            name: "Flutter",
            image: flutter,
        },
        {
            name: "ReactJS",
            image: react,
        },
        {
            name: "Bootstrap",
            image: bootstrapIcon,
        },
        {
            name: "Docker",
            image: dockerIcon,
        },
        {
            name: "Yii2",
            image: yii,
        },
        {
            name: "WordPress",
            image: wordpress,
        },
        {
            name: "MariaDB",
            image: mariadb,
        },
    ];

    const myProjects = [
        {
            name: en ? "Mokawenat" : "مكونات",
            image: mokawenatBrand,
            description: en ? "A platform for everything about Electronics" : "موقع لكل شيء عن الإليكترونيات",
            buttonText: en ? "Visit Website" : "فتح الموقع",
            link: "https://mokawenat.com",
            role: en ? "If you like and learn electronics this would be a great place to go!" : "إان كنت تحب عالم الكهرباء والإليكترونيات أعدك انه سيكون مكان رائع لذلك"
        },
        {
            name: "Shams M. Monem's Blog",
            image: blogBrand,
            description: en ? "My personal blog were I write posts about different topics in electronics, physics, science, etc..."
                : "موقعي الخاص الذي انشر فيه المقالات عن مختلف التخصصات في الإليكترونيات، فيزياء، علوم و ألخ",
            role: en ? "Also reason why I made my YouTube Channel!" : "أيضا السبب الذي جعلني أنشئ قناتي علي اليوتيوب!",
            buttonText: en ? "Visit Website" : "فتح الموقع",
            link: "https://shamsmm.com",
        },
        {
            name: en ? "Al-Abgady App" : "تطبيق الأبجدي",
            image: alabgadyBrand,
            description: en ? "An Islamic program whose aim is to explain the direct meaning of the Qur’anic words easily, whose meaning is difficult for the reader to understand."
                : "برنامج اسلامي تقوم خطته على شرح المعنى المباشر للالفاظ القرآنية التى يصعب على القارئ معرفة معناها ..",
            role: en ? "All thanks and appreciation to Acct. Ahmed Fahmy, My Grandfather, the creator of idea and who spent months in collecting the meanings from multiple resources."
                : "كل الشكر والتقدير للمحاسب أحمد فهمي، جدي، وصاحب الفكرة ومن قضي شهور عديدة في تجميع وتحصيل المعاني من مختلف المصادر.",
            buttonText: en ? "Download App" : "حمل التطبيق",
            link: "https://zedony.com/abgady-mobile",
        },
        {
            name: "MySkillsDiary",
            image: msdBrand,
            description: "Platform that encourages students & everyone to develop their skills, find people with common interests",
            role: "I was a Full-Stack Developer & System Administrator",
            buttonText: en ? "Visit Website" : "فتح الموقع",
            link: "http://myskillsdiary.com",
        },
        {
            name: en ? "Madinaty Bus Schedule App" : "تطبيق مواعيد باص مدينتي",
            description: en ? "An app for my local city for Bus schedule" : "برنامج لمواعيد باصات مدينة مدينتي",
            role: "Was a Mobile Developer",
            buttonText: en ? "Download App" : "حمل التطبيق",
            link: "https://ilovemadinaty.com/bus/mobile",
        },
        {
            name: "Zedony News",
            description: "An extremely simple Egyptian News Application powered officially by Zedony.com",
            role: "Was a Mobile Developer",
            buttonText: en ? "Download App" : "حمل التطبيق",
            link: "https://zedony.com/mobile",
        },
        {
            name: "SMARTHA",
            description: "Smart Home Automation, easy and cheap.",
            buttonText: "Visit Github repository",
            link: "https://github.com/shamsmm/smartha",
        },
    ];

    return (
        <div>
            <div className={"text-center px-3 py-5 mt-3"} style={{background: "linear-gradient(to bottom right, hsl(281deg 100% 53%) 0%, hsl(55deg 96% 46%) 100%)"}}>
                <h2 className={"text-light"}>{t("My Projects")}</h2>
                <Container>
                    <Row className={"justify-content-center pt-5 gy-3"}>
                        {myProjects.map((p) => <Col lg={4}>
                            <div className="card border-0 shadow-sm">
                                {p.image && <img src={p.image} className="card-img-top" alt="..."/>}
                                <div className="card-body">
                                    <h5 className="card-title">{p.name}</h5>
                                    <p className="card-text">{p.description}</p>
                                </div>
                                <div className="card-body">
                                    <a href={p.link} target={"_blank"} className="card-link">{p.buttonText}</a>
                                </div>
                                {p.role && <div className="card-footer text-muted">
                                    {p.role}
                                </div>}
                            </div>
                        </Col>)}
                    </Row>
                </Container>
            </div>
            <div className={"bg-transparent text-dark text-center px-3 py-5 mt-5"}>
                <h2>{t("Stuff I Do")}</h2>
                <Container>
                    <Row className={"justify-content-center pt-5 gy-3"}>
                        {stuffIDo.map((l) => <Col lg={3}>
                            <div className={"text-center"}>
                                <img src={l.image} className={"rounded-3"} alt={l.name} width={64}/>
                                <h6 className={"mt-2"}>{l.name}</h6>
                                <Button as={"a"} href={l.link} variant={"outline-" + l.color} size={"sm"} className={"rounded-pill"}>
                                    {l.buttonText}
                                </Button>
                            </div>
                        </Col>)}
                    </Row>
                </Container>
            </div>
            <div className={"text-center px-3 py-5 mt-5"} style={{background: "linear-gradient(to top right, rgb(255 210 210) 0%, rgb(212 255 66) 100%)"}}>
                <h2>{t("Technologies I Love & Use")}</h2>
                <Container>
                    <Row className={"justify-content-center pt-5 gy-3"}>
                        {technologiesIUse.map((l) => <Col lg={3}>
                            <div className={"text-center"}>
                                <img src={l.image} alt={l.name} width={64}/>
                                <h6 className={"mt-2"}>{l.name}</h6>
                                <Button as={"a"} href={l.link} variant={"outline-" + l.color} size={"sm"} className={"rounded-pill"}>
                                    {l.buttonText}
                                </Button>
                            </div>
                        </Col>)}
                    </Row>
                </Container>
            </div>
            <Row className={"m-0 mt-5 px-1 "}>
                <p className={"text-start small text-muted"}>
                    Icons by <a href={"https://icons8.com/"}>icons8.com</a>
                    <br/>
                    <a href="https://www.flaticon.com/free-icons/wordpress" title="wordpress icons">Wordpress icons created by Freepik - Flaticon</a>
                </p>
            </Row>
        </div>
    );
}

export default Home;