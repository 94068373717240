import About from "../views/about/About";
import Contact from "../views/contact/Contact";
import Home from "../views/home/Home";
import PrivacyPolicy from "../views/site/PrivacyPolicy";

const routes = [
    { path: '/', component: Home, slim: true},
    { path: '/about', component: About},
    { path: '/contact', component: Contact},
    { path: '/privacy-policy', component: PrivacyPolicy},
];

export default routes;