import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {Alert, Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import axios from "axios";

function Contact(props) {
    const {t} = useTranslation();
    const [contactData, setContactData] = useState({email: "", subject: "", body: ""});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [validated, setValidated] = useState(false);

    const onChange = (e) => {
        setContactData({...contactData, [e.target.name]: e.target.value})
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(true);

        if (form.checkValidity() === false) {
            event.stopPropagation()
        } else {
            setLoading(true);
            axios.post("/contact", contactData)
                .then((res) => {
                    event.target.reset();
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    setValidated(false);
                    setError("An error occurred!");
                });
        }
    }

    return (
        <>
            <Helmet>
                <title>Shams M. Monem | {t('Contact')}</title>
            </Helmet>
            <Row className={"justify-content-center mb-5"}>
                <h2 className={"text-center mb-5"}>{t("Contact")}</h2>
                <Col lg={5} md={7} sm={12}>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className={"needs-validation"}>
                        <Alert variant="warning">
                            <Alert.Heading>Hey, this form is not working!</Alert.Heading>
                            <p>
                                Please if you need to contact me, visit https://shamsmm.com/contact
                            </p>
                        </Alert>
                        {error != null &&
                            <Alert onClose={() => setError(null)} dismissible className={"alert-danger"}>
                                {error}
                            </Alert>
                        }
                        <Form.Group className="mb-3" >
                            <Form.Label>Your E-mail</Form.Label>
                            <Form.Control name={"email"} type="email" placeholder="name@example.com" onChange={onChange} required/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a non-empty appropriate value for your E-mail
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Subject</Form.Label>
                            <Form.Control name={"subject"} type="subject" placeholder="I have this cool idea ..." onChange={onChange} required minlength={10} maxlength={100}/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a non-empty value for your Subject between 10-100 characters
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Body</Form.Label>
                            <Form.Control name={"body"} as="textarea" rows={3} onChange={onChange} required minlength={50} maxlength={5000}/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a non-empty value for your Body between 50-5000 characters
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Col xs={6} className={"text-start"}>
                                <Button color="primary" className="px-4" type={"submit"}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default Contact;