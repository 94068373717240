import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {blogImg, mokawenatImg, youtubeImg} from "../assets";
import {useTranslation} from "react-i18next";



function Header(props) {
    const {t, i18n} = useTranslation();

    const quickLinks = [
        {
            name: t("Mokawenat"),
            image: mokawenatImg,
            buttonText: t("Explore"),
            color: "custom-1",
            link: "https://mokawenat.com",

        },
        {
            name: t("YouTube Channel"),
            image: youtubeImg,
            buttonText: t("Watch"),
            color: "danger",
            link: "https://youtube.com/shamsmmonem",
        },
        {
            name: t("My Blog"),
            image: blogImg,
            buttonText: t("Read"),
            color: "custom-4",
            link: "https://shamsmm.com",
        },
    ];

    return (
        <Container  className={"my-5"} fluid>
            <Row className={"justify-content-center"}>
                <Col lg={6} >
                    <div className={"text-center"}>
                        <h1 className={"main-name"}>
                            Shams M. Monem
                        </h1>
                        <p className={"text-dark fw-light"}>
                            {t("subhead")}
                        </p>
                    </div>
                </Col>
            </Row>
            <br/>
            <Row className={"justify-content-center gy-5"}>
                {quickLinks.map((l) => <Col lg={2}>
                    <div className={"text-center"}>
                        <img src={l.image} className={"rounded-3"} alt={l.name} width={64}/>
                        <h6 className={"mt-2"}>{l.name}</h6>
                        <Button as={"a"} target={"_blank"} href={l.link} variant={"outline-" + l.color} size={"sm"} className={"rounded-pill mt-2"}>
                            {l.buttonText}
                        </Button>
                    </div>
                </Col>)}
            </Row>
        </Container>
    );
}

export default Header;