import React from 'react';

/**
 * The theme components only imports its theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const DefaultStyle = React.lazy(() => import('./DefaultStyle'));
const DefaultStyleRTL = React.lazy(() => import('./DefaultStyleRTL'));

const StyleSelector: React.FC = ({ language, children }) => (
    <>
        <React.Suspense fallback={() => <h1>Loading...</h1>}>
            {language !== "ar" && <DefaultStyle />}
            {language === "ar" && <DefaultStyleRTL />}

            {children}
        </React.Suspense>
    </>
);

export default StyleSelector;