import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function About(props) {
    const {t, i18n} = useTranslation();

    return (
        <Container>
            <Helmet>
                <title>Shams M. Monem | {t('About')}</title>
            </Helmet>
            <Row className={"justify-content-center"}>
                <Col lg={8}>
                    <h2 className={"text-center mb-5"}>{t("About")}</h2>
                    {i18n.language === "en" ? <>
                        <p>
                            I am a normal school student, but I do lots of things beside my school studying, I love a lot of hobbies of which are Programming; to build websites, android applications, embedded systems, Electrical Engineering that have been a daily must to always learn new thing about it or design a new electric circuit or study new rules, Astronomy; I love observing stars, learn new facts about our enormously big universe and at last Physics, with all it's branches from normal Kinematics movements to astrophysics to even the weird world of quantum physics. I would always  read about a subject or preform and experiment or code a thing on a daily basis.
                        </p>
                        <p>
                            Out of all of these I found Electronics &amp; Programming my favourite hobbies thus I have decent knowledge about them and I always seek learning new things, and to teach new people though my own way with my own perspective not as a teacher but a student.
                        </p>
                    </> : <>
                        <p>
                            أنا طالب عادي في مدرسة، لكني أفعل الكثير من الأشياء بجانب دراساتي ، أحب الكثير من الهوايات التي منها البرمجة ؛ لبناء مواقع الويب وتطبيقات android، والهندسة الكهربائية التي يوميا اتعلم فيها أشياء جديدة عنها أو اصمم دائرة كهربائية جديدة أو اتعلم قواعد جديدة ، علم الفلك ؛ فانا أحب مراقبة النجوم ، وتعلم حقائق جديدة حول كوننا الضخم للغاية والرائع، وأخيراً الفيزياء ، بكل فروعها الطبيعية إلى الفيزياء الفلكية وحتى العالم الغريب لفيزياء الكم.
                        </p>
                        <p>
                            من كل هذه وجدت الالكترونيات والبرمجة هواياتي المفضلة فبالتالي لدي معرفة جيدة عنهم، ولكني أسعى دائما لتعلم أشياء جديدة، وتعليم الناس الجديدة بطريقتي الخاصة مع وجهة نظر اني طالب.
                        </p>
                    </>}

                </Col>
            </Row>
        </Container>
    );
}

export default About;