import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {motion} from 'framer-motion';
import {useTranslation} from "react-i18next";

function NotFound() {
    const {t} = useTranslation();

    return (
        <div>
            <Container className={"mt-3 mb-5"}>
                <Row>
                    <Col lg={6} className={"mx-auto text-center"}>
                        <h1 className={"text-danger"} style={{fontSize: "8rem"}} >404!</h1>
                        <p>{t('The requested resource is not found!')}</p>
                        <p>
                            <Link to={"/contact"}>{t('Contact')}</Link> {t('Us if you think that this is a problem with the server.')}
                        </p>
                        <Link to={"/"} className={"btn btn-outline-success rounded-pill"} >{t('Go to Home')}</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default NotFound;