import React, {useState} from 'react';
import {Container, Nav, Offcanvas} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import {Link, NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

function UserNavigation({ handleLogout, user}) {
    const {t} = useTranslation();

    return (
        <Navbar bg="white" variant="light" expand={"md"} className="mb-3 custom-navbar" >
            <Container>
                <Navbar.Brand as={Link} to={"/"} className={""}>Shams M. Monem</Navbar.Brand>
                <Navbar.Toggle aria-controls={"off-canvas-navbar-expand-md"} />
                <Navbar.Offcanvas
                    id={"off-canvas-navbar-expand-md"}
                    aria-labelledby={"off-canvas-navbar-expand-md"}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={"off-canvas-navbar-expand-md"}>
                            {t('Navigation')}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Nav.Link as={NavLink} to={"/about"} >
                                {t('About')}
                            </Nav.Link>
                            <div className={"vr mx-2 d-none d-md-block h-50 m-auto"}></div>
                            <Nav.Link as={NavLink} to={"/contact"} >
                                {t('Contact')}
                            </Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}

export default UserNavigation;