import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function PrivacyPolicy(props) {
    const {t, i18n} = useTranslation();

    return (
        <Container>
            <Helmet>
                <title>Shams M. Monem | {t('Privacy Policy')}</title>
            </Helmet>
            <Row className={"justify-content-center"}>
                <Col lg={8}>
                    <h2 className={"text-center mb-5"}>{t("Privacy Policy")}</h2>
                    {i18n.language === "en" ? <>
                        <h3>Cookie Policy</h3>
                        <p>
                            To provide the best experience and seamless transition between website languages, the website may store session or cookie data including user language and user may choose to save name, e-mail address to customize his experience while posting comments and other related cookies/session data including google analytics (see Analytics section), and/or embedded content(see Youtube & embedded links policy), plus Facebook services.
                        </p>
                        <h3>Analytics Services</h3>
                        <p>
                            This site uses only Google analytics service to collect data about visitors to enhance website internationally, data collected by Google analytics include device (mobile & desktop) country and other data, you can read Google Analytics privacy policy if you wish.
                        </p>
                    </> : <>
                        <h3>السياسة الخصوصية المتعلقة بالكوكيز "Cookie Policy"</h3>
                        <p>
                            لتوفير افضل الخدمات والانتقال السلس بين لغات الموقع، فالموقع يقوم بحفظ بيانات الحصة و الكوكيز التي من ضمنها لغة المستخدم او باالامكان حفظ الاسم و البريد الاليكتروني لتسهيل التعليق في المرات المقبلة، بالاضافة الي خدمات اخري من ضمنها بيانات Google Analytics او بيانات الروابط الحية مثل يوتيوب و مواقع اخري و ايضا خدمات الفيسبوك.
                        </p>
                        <h3>خدمات التحليلات "Analytics Services" </h3>
                        <p>
                            يتم استخدام التحليلات للموقع عن طريق خدمة جوجل فقط، هذا لتحسين و متابعة اداء الوقع علي المستوي العالمي، البيانات التي يتم تجميعها عبر هذه الخدمة من ضمنها نوع الجهاز(هاتف جوال ام كمبيوتر)، البلد و بيانات اخري، يمكنك القاء نظرة علي السياسة الخصوصية لدي جوجل ان اردت
                        </p>
                    </>}

                </Col>
            </Row>
        </Container>
    );
}

export default PrivacyPolicy;